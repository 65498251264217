/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';
import Form from '~/components/Form';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import DisplayError from '~/util/DisplayError';

import api from '~/services/api';

import { Description, Container, RadioGroup, Label } from './styles';

const MySwal = withReactContent(Swal);

function SendNotification() {
  const history = useHistory();
  const [segments, setSegments] = useState([]);
  const [alertTypes, setAlertTypes] = useState([]);

  const { sendType, alertTypeId } = history.location.state;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      type: 'newsBoard',
    },
  });

  const currentType = watch('type');
  const redirectType = watch('redirect');

  useEffect(() => {
    async function fetchAlertTypes() {
      try {
        const { data } = await api.get('/alert/type');
        setAlertTypes(data);
      } catch (err) {
        const { response } = err;
        if (!err.response) {
          DisplayError('Verifique sua conexão e tente novamente');
          return;
        }
        DisplayError(
          response?.data?.error ||
            'Ocorreu um erro ao listar os dados, tente novamente mais tarde'
        );
      }
    }
    async function fetchSegments() {
      try {
        const { data } = await api.get('/oneSignal/segments');
        setSegments(data.segments);
      } catch (err) {
        const { response } = err;
        if (!err.response) {
          DisplayError('Verifique sua conexão e tente novamente');
          return;
        }
        DisplayError(
          response?.data?.error ||
            'Ocorreu um erro ao listar os dados, tente novamente mais tarde'
        );
      }
    }

    fetchAlertTypes();
    fetchSegments();
  }, []);

  useEffect(() => {
    if (typeof alertTypeId !== 'undefined' && alertTypes) {
      setValue('alertType', alertTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertTypes, alertTypeId]);

  useEffect(() => {
    if (sendType) {
      setValue('type', sendType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendType]);

  function extractSegments(segment) {
    return Object.entries(segment)
      .map(([key, value]) => {
        if (value) return key;
        return null;
      })
      .filter((item) => item);
  }

  function extractAdditionalData(data) {
    const extractedData = {};
    data.forEach((additional) => {
      extractedData[additional.key] = additional.value;
    });
    return extractedData;
  }

  async function sendAlert(data) {
    const { title, content, alertType: type_id } = data;
    try {
      await api.post('/alert', {
        title,
        content,
        type_id,
      });
      MySwal.fire({
        icon: 'success',
        title: 'Alerta criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push(`/alert/${type_id}`);
    } catch (err) {
      const { response } = err;
      if (!err.response) {
        DisplayError('Verifique sua conexão e tente novamente');
        return;
      }
      DisplayError(
        response?.data?.error ||
          'Não foi possível criar um alerta, tente novamente mais tarde'
      );
    }
  }

  async function sendPush(data) {
    const { title, content, segment, redirect, custom_link } = data;

    let obj = {
      title,
      content,
      segments: extractSegments(segment),
      //        data: extractAdditionalData(additional),
    };

    if (redirectType === 'RedirectLink') {
      obj = {
        ...obj,
        url: custom_link,
      };
    } else {
      obj = {
        ...obj,
        data: {
          redirect,
        },
      };
    }

    try {
      await api.post('/onesignal/send', obj);
      MySwal.fire({
        icon: 'success',
        title: 'Push criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/notification');
    } catch (err) {
      const { response } = err;
      if (!err.response) {
        DisplayError('Verifique sua conexão e tente novamente');
        return;
      }
      DisplayError(
        response?.data?.error ||
          'Não foi possível criar um alerta e um push, tente novamente mais tarde'
      );
    }
  }

  async function sendAlertAndPush(data) {
    const {
      title,
      content,
      segment,
      alertType: type_id,
      additionalData: additional,
    } = data;
    try {
      await api.post('/alert', {
        title,
        content,
        type_id,
      });
      await api.post('/onesignal/send', {
        title,
        content,
        segments: extractSegments(segment),
        data: extractAdditionalData(additional),
      });
      MySwal.fire({
        icon: 'success',
        title: 'Alerta e push criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/notification');
    } catch (err) {
      const { response } = err;
      if (!err.response) {
        DisplayError('Verifique sua conexão e tente novamente');
        return;
      }
      DisplayError(
        response?.data?.error ||
          'Não foi possível criar um alerta e um push, tente novamente mais tarde'
      );
    }
  }

  async function onSubmit(data) {
    const { type } = data;
    switch (type) {
      case 'newsBoard':
        await sendAlert(data);
        break;
      case 'all':
        await sendAlertAndPush(data);
        break;
      case 'push':
        await sendPush(data);
        break;
      default:
        break;
    }
  }

  return (
    <Container
      style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
    >
      <Description>
        Preencha os campos abaixo para enviar uma notificação aos usuários
      </Description>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>Forma de envio</h4>
        <RadioGroup>
          <input
            value="newsBoard"
            type="radio"
            id="newsBoard"
            {...register('type', {
              required: true,
            })}
          />
          <Label htmlFor="newsBoard">Mural de novidades</Label>
          <br />

          <input
            value="push"
            type="radio"
            id="push"
            {...register('type', { required: true })}
          />
          <Label htmlFor="push">Push Notifications</Label>
          <br />

          <input
            value="all"
            type="radio"
            id="all"
            {...register('type', { required: true })}
          />
          <Label htmlFor="all">Ambos</Label>
          {errors.type && <TextError>Este campo é obrigatório</TextError>}
        </RadioGroup>

        <Controller
          control={control}
          name="title"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              onChange={onChange}
              value={value}
              name="title"
              label="Título"
            />
          )}
        />
        {errors.title && <TextError>Este campo é obrigatório</TextError>}

        <Controller
          control={control}
          name="content"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextArea
              style={{ marginBottom: 20 }}
              cols="21"
              rows="5"
              onChange={onChange}
              value={value}
              name="content"
              label="Conteúdo"
            />
          )}
        />
        {errors.content && <TextError>Este campo é obrigatório</TextError>}

        {currentType !== 'newsBoard' && (
          <>
            <h4>Redirecionar para:</h4>
            <RadioGroup>
              <input
                value="Dashboard"
                type="radio"
                id="redirectDashboard"
                {...register('redirect', { required: false })}
              />
              <Label htmlFor="redirectDashboard">Página principal</Label>
            </RadioGroup>
            <RadioGroup>
              <input
                value="Invoice"
                type="radio"
                id="redirectInvoice"
                {...register('redirect', { required: false })}
              />
              <Label htmlFor="redirectInvoice">Faturas</Label>
            </RadioGroup>
            <RadioGroup>
              <input
                value="Suporte"
                type="radio"
                id="redirectSupport"
                {...register('redirect', { required: false })}
              />
              <Label htmlFor="redirectSupport">Suporte</Label>
            </RadioGroup>
            <RadioGroup>
              <input
                value="SettingsPersonalData"
                type="radio"
                id="redirectPersonal"
                {...register('redirect', { required: false })}
              />
              <Label htmlFor="redirectPersonal">Alterar cadastro</Label>
            </RadioGroup>
            <RadioGroup>
              <input
                value="RedirectLink"
                type="radio"
                id="redirectLink"
                {...register('redirect', { required: false })}
              />
              <Label htmlFor="redirectLink">Link http</Label>
            </RadioGroup>

            {redirectType === 'RedirectLink' && (
              <div style={{ marginBottom: 10 }}>
                <Controller
                  control={control}
                  name="custom_link"
                  rules={{
                    required: redirectType === 'RedirectLink',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      name="custom_link"
                      label="URL"
                    />
                  )}
                />
                {errors.custom_link && (
                  <TextError>Este campo é obrigatório</TextError>
                )}
              </div>
            )}
          </>
        )}
        {currentType !== 'newsBoard' && <h4>Segmentos</h4>}
        {currentType !== 'newsBoard' &&
          segments &&
          segments.map((seg) => (
            <div key={seg.id}>
              <input
                type="checkbox"
                id="partners"
                {...register(`segment.${seg.name}`)}
              />
              <Label htmlFor={seg.name}>{seg.name}</Label>
              <br />
            </div>
          ))}
        {currentType !== 'push' && <h4>Tipos de alertas</h4>}
        {currentType !== 'push' && (
          <RadioGroup>
            {alertTypes &&
              alertTypes.map((type) => (
                <div key={type.id}>
                  <input
                    type="radio"
                    id="alertType"
                    value={type.id}
                    {...register(`alertType`)}
                  />
                  <Label htmlFor={type.name}>{type.name}</Label>
                  <br />
                </div>
              ))}
          </RadioGroup>
        )}
        {/*
        <input
          type="checkbox"
          id="features"
          {...register('segment.features')}
        />
        <Label htmlFor="features">Parceiros</Label>
        */}
        {errors.segment && <TextError>Este campo é obrigatório</TextError>}

        <Button type="submit">Enviar</Button>
      </Form>
    </Container>
  );
}

export default SendNotification;

export function signIn(name, token) {
  return {
    type: '@auth/SIGN_IN',
    payload: {
      name,
      token,
    },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function setToken() {
  return {
    type: '@AUTH/SET_TOKEN',
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Container, Title, Content } from './styles';

import UDOptions from '~/components/UDOptions';

function Alert({ alert, editOnPress, deleteOnPress }) {
  const { title, fullTitle, content, fullContent, createdAt } = alert;
  return (
    <Container>
      <Title
        to={{
          pathname: '/alert/read',
          state: {
            fullTitle,
            fullContent,
          },
        }}
      >
        {title}
      </Title>
      <Content>{content}</Content>
      <Content>{format(new Date(createdAt), 'd/M/yyyy HH:mm:ss')}</Content>
      <UDOptions
        options={{
          update: false,
          destroy: true,
        }}
        editOnPress={editOnPress}
        deleteOnPress={deleteOnPress}
      />
    </Container>
  );
}

Alert.propTypes = {
  alert: PropTypes.shape({
    fullTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    fullContent: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default Alert;

import React from 'react';
import PropTypes from 'prop-types';

import { Container, Image, ResizedImageText } from './styles';

function ImageShowcase({ src }) {
  return (
    <Container>
      <Image src={src} />
      <ResizedImageText>Imagem redimensionada</ResizedImageText>
    </Container>
  );
}

ImageShowcase.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImageShowcase;

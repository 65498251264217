import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Input from '~/components/Input';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

import {
  requiredValidation,
  passwordValidationRequired,
} from '~/util/adminValidation';

const MySwal = withReactContent(Swal);

export default function AdminCreate() {
  const history = useHistory();
  const [errors, setErrors] = useState({
    name: '',
    login: '',
    password: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    login: '',
    password: '',
  });

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'name' || e.target.name === 'login') {
      errorsTemp[e.target.name] = requiredValidation(e.target.value);
    } else if (e.target.name === 'password') {
      errorsTemp.password = passwordValidationRequired(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorsTemp = {
      name: requiredValidation(formData.name),
      login: requiredValidation(formData.login),
      password: passwordValidationRequired(formData.password),
    };
    setErrors(errorsTemp);
    if (errorsTemp.name || errorsTemp.login || errors.password) {
      return;
    }

    try {
      await api.post('/admin/create', {
        login: formData.login,
        name: formData.name,
        password: formData.password,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Administrador criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/admin');
    } catch (err) {
      if (err.response) {
        const { error } = err?.response?.data;
        DisplayError(
          error ||
            'Não foi possível criar o administrador, tente novamente mais tarde e se o erro persistir contate o suporte'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Nome"
            placeholder="João da Silva"
            name="name"
            onChange={handleChange}
            value={formData.name}
          />
          {errors.name && <TextError>{errors.name}</TextError>}
          <Input
            label="Login"
            placeholder="exemplo1020"
            name="login"
            onChange={handleChange}
            value={formData.login}
          />
          {errors.login && <TextError>{errors.login}</TextError>}
          <Input
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            value={formData.password}
          />
          {errors.password && <TextError>{errors.password}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

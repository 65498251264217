import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Container, Form, FormTitle } from './styles';

import Input from '~/components/Input';
import Button from '~/components/Button';
import TextError from '~/components/TextError';

import api from '~/services/api';
import history from '~/services/history';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import { signIn } from '~/store/modules/auth/actions';

import iconUser from '~/assets/icon-user.svg';
import iconPassword from '~/assets/icon-password.svg';

export default function SignIn() {
  const dispatch = useDispatch();
  const signed = useSelector((state) => state.auth.signed);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    login: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    login: '',
    password: '',
  });

  useEffect(() => {
    if (signed) {
      history.push('/dashboard');
    }
  }, [signed]);

  const loginValidation = (login) => {
    if (!login) return 'Campo obrigatório';
    return '';
  };

  const passwordValidation = (password) => {
    if (!password) return 'Campo obrigatório';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errorsTemp = errors;
    if (e.target.name === 'login') {
      errorsTemp.login = loginValidation(e.target.value);
    }
    if (e.target.name === 'password') {
      errorsTemp.password = passwordValidation(e.target.value);
    }
    if (errorsTemp.login || errorsTemp.password) return;

    setErrors(errorsTemp);
    setLoading(true);

    try {
      const { name, token } = (
        await api.post('/admin/login', {
          login: formData.login,
          password: formData.password,
        })
      ).data;
      dispatch(signIn(name, token));
    } catch (err) {
      setLoading(false);
      if (!err.response) {
        networkError();
        return;
      }
      if (err.response.status === 401) {
        DisplayError('Credenciais inválidas');
        return;
      }
      DisplayError(
        err?.response?.data?.error ||
          'Ocorreu um erro ao tentar fazer login, por favor, tente novamente mais tarde'
      );
    }
  };

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'login') {
      errorsTemp.login = loginValidation(e.target.value);
    } else if (e.target.name === 'password') {
      errorsTemp.password = passwordValidation(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <FormTitle>Faça o login</FormTitle>
      <Form onSubmit={handleSubmit}>
        <Input
          label="Login"
          name="login"
          onChange={handleChange}
          placeholder="Login"
          icon={() => <img src={iconUser} alt="" />}
        />
        {errors.login && <TextError>{errors.login}</TextError>}
        <Input
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          placeholder="Senha"
          icon={() => <img src={iconPassword} alt="" />}
        />
        {errors.password && <TextError>{errors.password}</TextError>}
        <Button loading={loading}>Logar</Button>
      </Form>
    </Container>
  );
}

import axios from 'axios';

export const baseURL = 'https://appwavemax.midiasimples.com.br';
// export const baseURL = 'http://192.168.1.4:6464';
// export const baseURL = 'http://192.168.10.108:6464';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const api = axios.create({
  baseURL,
  timeout: 20000,
});

export default api;

import { signOut } from '~/store/modules/auth/actions';
import DisplayError from '~/util/DisplayError';

export default function apiInterceptor(api, store) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const { signed } = store.getState().auth;
      const UNAUTHORIZED = 401;
      const { status } = error.response;
      if (status === UNAUTHORIZED && signed) {
        DisplayError('Sessão expirada ou inválida');
        store.dispatch(signOut());
      }
      // 401 desloga
      return Promise.reject(error);
    }
  );
}

import { toast } from 'react-toastify';

export default function DisplayError(value) {
  toast.error(typeof value === 'string' ? value : JSON.stringify(value), {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

import React from 'react';
import PropTypes from 'prop-types';

import { Container, Values } from './styles';

import UDOptions from '~/components/UDOptions';

function AdminCard({ name, login, id, editOnPress, deleteOnPress, ...rest }) {
  return (
    <Container {...rest}>
      <Values>Id: {id}</Values>
      <Values>Nome: {name}</Values>
      <Values>Login: {login}</Values>
      <UDOptions editOnPress={editOnPress} deleteOnPress={deleteOnPress} />
    </Container>
  );
}

AdminCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default AdminCard;

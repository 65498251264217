import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import Card from '~/components/Card';
import Container from '~/components/DefaultContainer';

import networkError from '~/util/networkError';
import DisplayError from '~/util/DisplayError';

import AlertType from './AlertType';

export default function AlertTypeList() {
  const [alertTypes, setAlertTypes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchAlertTypes() {
      try {
        setAlertTypes(
          (await api.get('/alert/type')).data.map((item) => ({
            ...item,
            name:
              item.name.length > 15
                ? `${item.name.slice(0, 15)}...`
                : item.name,
          }))
        );
      } catch (err) {
        if (!err.response) {
          networkError();
          return;
        }
        DisplayError(
          err?.response?.data?.error ||
            'Não foi possível listar tópicos, por favor, tente novamente mais tarde'
        );
      }
    }
    fetchAlertTypes();
  }, []);

  return (
    <Container>
      <Card redirectTo="/alert/type/create">Adicionar tipo de alerta</Card>
      {alertTypes &&
        alertTypes.map((alertType) => (
          <AlertType
            key={alertType.id}
            id={alertType.id}
            name={alertType.name}
            editOnPress={() => {
              history.push({
                pathname: `/alert/type/update/${alertType.id}`,
                state: {
                  alertType,
                },
              });
            }}
            deleteOnPress={() => {
              history.push({
                pathname: `/alert/type/delete/${alertType.id}`,
                state: {
                  alertType,
                },
              });
            }}
          />
        ))}
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Input from '~/components/Input';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

const MySwal = withReactContent(Swal);

export default function FAQTopicsUpdate() {
  const history = useHistory();
  const [errors, setErrors] = useState({
    title: '',
  });
  const [formData, setFormData] = useState({
    title: '',
  });
  const { topic } = history.location.state || {};

  const handleValidation = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'title') {
      if (!e.target.value) {
        errorsTemp.title = 'Campo obrigatório';
      } else {
        errorsTemp.title = '';
      }
    } else if (!formData.title) {
      errorsTemp.title = 'Campo obrigatório';
    } else {
      errorsTemp.title = '';
    }
    return errorsTemp;
  };

  const handleChange = (e) => {
    setErrors(handleValidation(e));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const errorsTemp = handleValidation(e);
    setErrors(errorsTemp);
    e.preventDefault();

    if (errorsTemp.title) return;

    try {
      await api.put(`/faq/${topic.id}`, {
        title: formData.title,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Tópico atualizado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/faq/topics');
    } catch (err) {
      if (err.response) {
        DisplayError(
          err?.response?.data?.error ||
            'Não foi possível atualizar o tópico, por favor, tente novamente mais tarde'
        );
      } else {
        networkError();
      }
    }
  };

  useEffect(() => {
    setFormData({
      title: topic.title,
    });
  }, []);

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Tópico"
            name="title"
            onChange={handleChange}
            value={formData.title}
          />
          {errors && <TextError>{errors.title}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

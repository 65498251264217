import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isBefore, parseISO } from 'date-fns';

import api from '~/services/api';
import isValidURL from '~/util/isValidURL';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import ControlledInput from '~/components/ControlledInput';
import FileInput from '~/components/FileInput';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import ImageShowcase from '~/components/ImageShowcase';
import Form from '~/components/Form';

const MySwal = withReactContent(Swal);

export default function BannerCreate() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const history = useHistory();
  const [toUploadImage, setToUploadImage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = async ({
    slug,
    priority,
    expirationDate,
    file,
    display_to,
  }) => {
    try {
      if (!file) {
        setError('file', {
          type: 'required',
          message: 'É necessário enviar uma imagem',
        });
        return;
      }

      const formDataToSend = new FormData();

      formDataToSend.append('slug', slug);
      formDataToSend.append('priority', priority);
      formDataToSend.append('display_to', display_to);
      formDataToSend.append('file', file);

      const formatted_date = parseISO(expirationDate);
      if (expirationDate) {
        formDataToSend.append('expiration_date', formatted_date.toISOString());
      }

      await api.post('/banner/create', formDataToSend, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });

      MySwal.fire({
        icon: 'success',
        title: 'Banner criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/banner');
    } catch (err) {
      if (err.response) {
        const { error } = err?.response?.data;
        DisplayError(
          error ||
            'Não foi possível criar o banner, tente novamente mais tarde e se o erro persistir contate o suporte'
        );
      } else {
        networkError();
      }
    }
  };

  function handleFileChoose(e) {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      setToUploadImage(event.target.result);
    };
    reader.readAsDataURL(file);
    clearErrors('file');

    setValue('file', file);
  }

  return (
    <Container>
      <div>
        {toUploadImage && <ImageShowcase src={toUploadImage} alt="" />}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FileInput
            name="file"
            label="Imagem"
            accept="image/*"
            onChange={handleFileChoose}
          />
          <p
            style={{
              marginBottom: '10px',
              fontSize: '.75rem',
              color: '#FBBA00',
              fontWeight: '500',
            }}
          >
            Proporção ideal de Imagens 1:1,2
          </p>

          {errors.file && <TextError>{errors.file.message}</TextError>}
          <ControlledInput
            name="slug"
            placeholder="https://wavemax.com.br/"
            label="Slug (Link para redireção)"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
              // validate: (value) => isValidURL(value) || 'Link inválido',
            }}
          />
          {errors.slug && <TextError>{errors.slug.message}</TextError>}
          <ControlledInput
            label="Prioridade(para aparecer antes de outro banner)"
            placeholder="0"
            type="number"
            name="priority"
            register={register}
            defaultValue={0}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.priority && <TextError>{errors.priority.message}</TextError>}
          <ControlledInput
            label="Exibir(0 = Assinantes / 1 = Não Assinantes)"
            placeholder="0"
            type="number"
            name="display_to"
            register={register}
            defaultValue={0}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.display_to && (
            <TextError>{errors.display_to.message}</TextError>
          )}
          {!isChecked && (
            <ControlledInput
              name="expirationDate"
              label="Data de expiração"
              placeholder="dd/mm/aaaa"
              type="date"
              register={register}
              registerOptions={{
                validate: (value) =>
                  isBefore(new Date(), new Date(value)) || 'Data inválida',
              }}
            />
          )}
          {errors.expirationDate && (
            <TextError>{errors.expirationDate.message}</TextError>
          )}
          <label htmlFor="lifetimeCheck">
            Banner vitalício?
            <input
              style={{ marginLeft: 10, marginTop: 15 }}
              type="checkbox"
              name="lifetimeCheck"
              id="lifetimeCheck"
              onChange={() => setIsChecked(!isChecked)}
            />
          </label>

          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

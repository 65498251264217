import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import { WarningText } from './styles';

import api from '~/services/api';

import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import Form from '~/components/Form';

import networkError from '~/util/networkError';
import DisplayError from '~/util/DisplayError';

const MySwal = withReactContent(Swal);

export default function FAQTopicsDelete() {
  const history = useHistory();
  const { topic } = history.location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.delete(`/faq/${topic.id}`);

      MySwal.fire({
        icon: 'success',
        title: 'Tópico deletado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/faq/topics');
    } catch (err) {
      if (err.response) {
        DisplayError(
          err?.response?.data?.error ||
            'Não foi possível deletar o tópico, por favor, tente novamente mais tarde'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <h1>Tem certeza que deseja deletar?</h1>
        <WarningText>
          AVISO: Todos os itens relacionados ao tópicos também serão apagados!
        </WarningText>
        <Form onSubmit={handleSubmit}>
          <Button warning>Deletar</Button>
        </Form>
      </div>
    </Container>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { Container, IconContainer, InputStyle } from './styles';

function Input({
  icon: Icon,
  label: labelString,
  register,
  registerOptions,
  name,
  ...rest
}) {
  return (
    <div style={{ marginTop: 10 }}>
      <label>{labelString}</label>
      <Container>
        {Icon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <InputStyle {...register(name, registerOptions)} {...rest} />
      </Container>
    </div>
  );
}

Input.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  registerOptions: PropTypes.shape({
    required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    validate: PropTypes.func,
  }),
};

Input.defaultProps = {
  icon: null,
  label: '',
  registerOptions: undefined,
};

export default Input;

export default {
  MAIN_THEME_COLOR: '#FBBA00',
  MAIN_THEME_COLOR_DARK: '#ffc522',
  MAIN_THEME_COLOR_DARKER: '#d59e00',
  TEXT_MILD_COLOR: '#5c5c5c',
  INPUT_BAR: '#d9d9d9',
  ERROR: 'red',
  WARNING: '#ff2a00',
  WARNING_DARK: '#c90000',
  WARNING_DARKER: '#990012',
  BLUE: '#6db4e8',
  SECOND_BLUE: '#33b4e8',
  THIRD_BLUE: '#09ade8',
};

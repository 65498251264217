import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Container, SeeItemsButton } from './styles';

import UDOptions from '~/components/UDOptions';

function AlertType({ id, name, editOnPress, deleteOnPress }) {
  const history = useHistory();

  return (
    <Container>
      <span>{name}</span>
      <div>
        <UDOptions
          noBottomRadius
          editOnPress={editOnPress}
          deleteOnPress={deleteOnPress}
        />
        <SeeItemsButton onClick={() => history.push(`/alert/${id}`)}>
          Ver itens
        </SeeItemsButton>
      </div>
    </Container>
  );
}

AlertType.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default AlertType;

import React from 'react';
import PropTypes from 'prop-types';

function FileInput({ name, label: labelString, ...rest }) {
  return (
    <>
      <label id={name} htmlFor={name}>
        {labelString}
      </label>
      <input
        id={name}
        name={name}
        type="file"
        {...rest}
        style={{
          borderRadius: '5px',
          padding: '10px',
          color: '#d9d9d9',
          border: '2px solid',
          display: 'block',
          width: '100%',
        }}
      />
    </>
  );
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FileInput;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  padding: 15px;
  flex-direction: column;
  background-color: #cfd9da;
  border: 1px solid black;
  justify-content: center;
  margin-left: 10px;
`;
export const Logo = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

export const TextContent = styled.div`
  margin-top: 15px;
  flex-direction: row;
`;

export const KeyText = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const ValueText = styled.span`
  margin-left: 5px;
  font-size: 16px;
`;

export const Site = styled.a`
  margin-left: 5px;
`;

export const DeleteButton = styled.button`
  padding: 15px;
  margin-left: auto;
  border-radius: 8px;
  color: white;
  border: none;
  font-weight: 600;
  margin-right: 15px;
  background-color: ${(props) => props.theme.WARNING_DARKER};
  &:hover {
    background-color: ${(props) => props.theme.WARNING_DARK};
  }
  &:active {
    background-color: ${(props) => props.theme.WARNING};
  }
`;

export const UpdateButton = styled.button`
  padding: 15px;
  margin-left: auto;
  border-radius: 8px;
  color: white;
  border: none;
  font-weight: 600;
  margin-right: 15px;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR_DARKER};
  &:hover {
    background-color: ${(props) => props.theme.MAIN_THEME_COLOR_DARK};
  }
  &:active {
    background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  }
`;

import React from 'react';

import Container from '~/components/DefaultContainer';

import Card from '~/components/Card';

export default function Dashboard() {
  return (
    <Container>
      <Card redirectTo="/banner">Gerenciar banners</Card>
      <Card redirectTo="/admin">Gerenciar administradores</Card>
      <Card redirectTo="/data">Gerenciar datas de vencimento</Card>
      <Card redirectTo="/faq/topics">Gerenciar FAQ</Card>
      <Card redirectTo="/notification">Gerenciar notificações</Card>
      <Card redirectTo="/alert/type">Gerenciar alertas</Card>
      <Card redirectTo="/stores">Gerenciar Lojas</Card>
      <Card redirectTo="/push-templates">
        Gerenciar templates de notificações
      </Card>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';

import { LoadingContainer } from './styles';

import Card from '~/components/Card';
import Container from '~/components/DefaultContainer';
import Loading from '~/components/Loading';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import NotificationItem from './NotificationItem';

function Notification() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchNotifications() {
      try {
        setNotifications((await api.get('/onesignal')).data.notifications);
      } catch (err) {
        if (!err.response) {
          networkError();
          return;
        }
        DisplayError(
          err?.response?.data?.err ||
            'Não foi possível listar as notificações, por favor, tente novamente mais tarde'
        );
      }
    }
    fetchNotifications();
  }, []);

  return (
    <Container>
      <Card redirectTo="/notification/send">Enviar notificação</Card>
      {notifications.length === 0 && (
        <LoadingContainer>
          <Loading themeColor />
        </LoadingContainer>
      )}
      {notifications &&
        notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            title={notification.headings.en}
            content={notification.contents.en}
            queuedAt={notification.queued_at}
          />
        ))}
    </Container>
  );
}

export default Notification;

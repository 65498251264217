import React from 'react';

import { useQuery } from 'react-query';
import api, { baseURL } from '~/services/api';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';
import PushCard from './PushCard';

export default function Index() {
  const { data, refetch } = useQuery({
    queryFn: () => api.get('/push-templates').then((res) => res.data),
    queryKey: 'push-templates',
  });

  return (
    <Container>
      {data?.map((pushTemplate) => (
        <PushCard {...pushTemplate} refetch={refetch} />
      ))}
      <Card redirectTo="/push-templates/create">
        Adicionar template de notificacao
      </Card>
    </Container>
  );
}

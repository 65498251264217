import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams, useHistory } from 'react-router-dom';

import api from '~/services/api';

import networkError from '~/util/networkError';
import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

import {
  titleValidation,
  contentValidation,
  topicItemValidation,
} from '~/util/topicItemValidation';

const MySwal = withReactContent(Swal);

export default function FAQItemsCreate() {
  const history = useHistory();
  const { id } = useParams();
  const [errors, setErrors] = useState({
    title: '',
    content: '',
  });
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'title') {
      errorsTemp.title = titleValidation(e.target.value);
    }
    if (e.target.name === 'content') {
      errorsTemp.content = contentValidation(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const errorsTemp = topicItemValidation(
      errors,
      formData.title,
      formData.content
    );
    setErrors(errorsTemp);
    e.preventDefault();

    if (errors.title || errors.content) return;

    try {
      await api.post('/faq/item/create', {
        title: formData.title,
        content: formData.content,
        topic_id: id,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Item de tópico criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push(`/faq/items/${id}`);
    } catch (err) {
      if (err.response) {
        DisplayError(
          err?.response?.data?.error ||
            'Não foi possível criar o item do tópico, por favor, tente novamente mais tarde'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input label="Título do item" name="title" onChange={handleChange} />
          {errors.title && <TextError>{errors.title}</TextError>}
          <TextArea
            label="Descrição do item"
            name="content"
            onChange={handleChange}
          />
          {errors.content && <TextError>{errors.content}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import { Footer, Content } from './styles';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

import useWindow from '~/util/useWindow';

function DefaultLayout({ children }) {
  const dimensions = useWindow();
  const isMobile = dimensions.width < 800;

  const Header = isMobile ? HeaderMobile : HeaderDesktop;

  return (
    <>
      <Header />
      <Content mobile={isMobile}>
        {children}
        <Footer />
      </Content>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};

export default DefaultLayout;

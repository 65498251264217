import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { baseURL } from '~/services/api';
import {
  Container,
  TextContent,
  KeyText,
  Logo,
  ValueText,
  Site,
  DeleteButton,
  UpdateButton,
} from './styles';

export default function StoreContainer({
  file,
  name,
  address,
  neighborhood,
  time,
  number,
  phone,
  site,
  store,
  ...rest
}) {
  const history = useHistory();

  return (
    <Container {...rest}>
      <Logo src={`${baseURL}/banner/${file}`} />
      <TextContent>
        <KeyText>Nome:</KeyText>
        <ValueText>{name}</ValueText>
      </TextContent>

      <TextContent>
        <KeyText>Endereço:</KeyText>
        <ValueText>{`${address},`}</ValueText>
        <ValueText>{number}</ValueText>
      </TextContent>

      <TextContent>
        <KeyText>Bairro:</KeyText>
        <ValueText>{neighborhood}</ValueText>
      </TextContent>

      <TextContent>
        <KeyText>Telefone:</KeyText>
        <ValueText>{phone}</ValueText>
      </TextContent>

      <TextContent>
        <KeyText>Horarios:</KeyText>
        <ValueText>{time}</ValueText>
      </TextContent>

      <TextContent>
        <KeyText>Site:</KeyText>
        <Site href={site}>{site}</Site>
      </TextContent>
      <TextContent>
        <DeleteButton
          onClick={() => {
            history.push({
              pathname: `/stores/delete/${store.id}`,
              state: { store },
            });
          }}
        >
          Excluir
        </DeleteButton>
        <UpdateButton
          onClick={() => {
            history.push({
              pathname: `/stores/update/${store.id}`,
              state: { store },
            });
          }}
        >
          Alterar
        </UpdateButton>
      </TextContent>
    </Container>
  );
}

StoreContainer.propTypes = {
  file: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  neighborhood: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  store: PropTypes.func.isRequired,
};

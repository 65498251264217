export function requiredValidation(value) {
  if (!value) {
    return 'Campo obrigatório';
  }
  return '';
}

export function passwordValidation(value) {
  if (value && (value.length < 6 || value.length > 40))
    return 'Senha deve ser entre 6 e 40 caracteres';
  return '';
}

export function passwordValidationRequired(value) {
  if (!value) return 'Campo obrigatório';
  if (value.length < 6 || value.length > 40)
    return 'Senha deve ser entre 6 e 40 caracteres';
  return '';
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UDOptions from '~/components/UDOptions';
import api from '~/services/api';
import DisplayError from '~/util/DisplayError';
import { Container } from './styles';

const MySwal = withReactContent(Swal);

const names = {
  FATURA_PAGA: 'Fatura paga',
  FATURA_VENCIDA: 'Fatura vencida',
  FATURA_VENCE_5_DIAS: 'Fatura vence em 5 dias',
  ANIVERSARIO: 'Aniversário',
  ANIVERSARIO_CADASTRO: '1 ano de Wavemax',
  NOVO_USUARIO: 'Novo cliente',
};

export default function PushCard({ id, title, content, name_id, refetch }) {
  const history = useHistory();

  return (
    <Container>
      <h1>{title}</h1>
      <p>{content}</p>
      <p>{names[name_id]}</p>
      <UDOptions
        editOnPress={() => {
          history.push({
            pathname: `/push-templates/${id}/update`,
            state: { item: { id, title, content, name_id } },
          });
        }}
        deleteOnPress={async () => {
          try {
            await api.delete(`/push-templates/${id}`);
            MySwal.fire({
              icon: 'success',
              title: 'Template deletado',
              timer: 1500,
              showConfirmButton: false,
            });
            refetch();
          } catch (err) {
            DisplayError(
              'Não foi possível deletar o template, por favor, tente mais tarde'
            );
          }
        }}
      />
    </Container>
  );
}

import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import ControlledInput from '~/components/ControlledInput';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';
import TextArea from '~/components/TextArea';

const MySwal = withReactContent(Swal);

export default function Create() {
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    if (history.location.state.item) {
      const { item } = history.location.state;
      const { name_id, title, content } = item;
      setValue('name_id', name_id);
      setValue('title', title);
      setValue('content', content);
    }
  }, [history.location.state, setValue]);

  const onSubmit = async ({ name_id, title, content }) => {
    try {
      await api.patch(`/push-templates/${id}`, {
        name_id,
        title,
        content,
      });
      MySwal.fire({
        icon: 'success',
        title: 'Template atualizado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/push-templates');
    } catch (err) {
      if (err.response) {
        const { error } = err?.response?.data;
        DisplayError(
          error ||
            'Não foi possível criar o template, tente novamente mais tarde e se o erro persistir contate o suporte'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledInput
            name="title"
            placeholder="Titulo"
            label="Titulo"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.title && <TextError>{errors.title.message}</TextError>}
          <Controller
            control={control}
            name="content"
            rules={{
              required: 'Campo obrigatório',
            }}
            render={({ field }) => (
              <TextArea placeholder="Conteudo" label="Conteudo" {...field} />
            )}
          />
          {errors.content && <TextError>{errors.content.message}</TextError>}
          <Controller
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            name="name_id"
            render={({ field }) => (
              <select {...field}>
                <option value="FATURA_VENCIDA">Fatura vencida</option>
                <option value="FATURA_VENCE_5_DIAS">
                  Fatura vence em 5 dias
                </option>
                <option value="FATURA_PAGA">Fatura paga</option>
                <option value="ANIVERSARIO">Aniversário</option>
                <option value="NOVO_USUARIO">Novo cliente</option>
                <option value="ANIVERSARIO_CADASTRO">1 ano de Wavemax</option>
              </select>
            )}
          />
          {errors.name_id && <TextError>{errors.name_id.message}</TextError>}
          <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;
`;

export const Image = styled.img`
  height: 100px;
`;

export const ResizedImageText = styled.span`
  display: block;
  font-size: 12px;
  color: ${(props) => props.theme.TEXT_MILD_COLOR};
`;

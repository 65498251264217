import React from 'react';
import PropTypes from 'prop-types';

import { Container, EditButton, DeleteButton } from './styles';

function UDOptions({
  editOnPress,
  deleteOnPress,
  noBottomRadius,
  options,
  ...rest
}) {
  return (
    <Container options={options} {...rest}>
      {options.update && (
        <EditButton
          options={options}
          noBottomRadius={noBottomRadius}
          onClick={() => editOnPress()}
        >
          Editar
        </EditButton>
      )}
      {options.destroy && (
        <DeleteButton
          options={options}
          noBottomRadius={noBottomRadius}
          onClick={() => deleteOnPress()}
        >
          Deletar
        </DeleteButton>
      )}
    </Container>
  );
}

UDOptions.propTypes = {
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
  noBottomRadius: PropTypes.bool,
  options: PropTypes.shape({
    update: PropTypes.bool.isRequired,
    destroy: PropTypes.bool.isRequired,
  }),
};

UDOptions.defaultProps = {
  noBottomRadius: false,
  options: {
    update: true,
    destroy: true,
  },
};

export default UDOptions;

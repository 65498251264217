import React from 'react';
import PropTypes from 'prop-types';

import loading from '~/assets/loading.svg';
import loadingTheme from '~/assets/loading-theme.svg';

import { Image } from './styles';

function Loading({ themeColor }) {
  return <Image src={themeColor ? loadingTheme : loading} alt="" />;
}

Loading.propTypes = {
  themeColor: PropTypes.bool,
};

Loading.defaultProps = {
  themeColor: false,
};

export default Loading;

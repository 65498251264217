import styled from 'styled-components';

export const Title = styled.h2`
  max-width: 90vw;
  word-wrap: break-word;
`;

export const Content = styled.p`
  word-wrap: break-word;
  width: 400px;
`;

import { all, takeLatest } from 'redux-saga/effects';
import { AUTH_SIGN_IN, PERSIST_REHYDRATE } from './types';

import api from '~/services/api';

function signIn({ payload }) {
  const { token } = payload;

  api.defaults.headers.Authorization = `Bearer ${token}`;
}

function setToken({ payload }) {
  const { token } = payload.auth;

  api.defaults.headers.Authorization = `Bearer ${token}`;
}

export default all([
  takeLatest(AUTH_SIGN_IN, signIn),
  takeLatest(PERSIST_REHYDRATE, setToken),
]);

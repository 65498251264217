import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: auto;
`;

export const Button = styled.button`
  padding: 15px;
  margin-left: auto;
  border-radius: 8px;
  color: white;
  border: none;
  font-weight: 600;
  background-color: ${(props) => props.theme.THIRD_BLUE};
  &:hover {
    background-color: ${(props) => props.theme.SECOND_BLUE};
  }
  &:active {
    background-color: ${(props) => props.theme.BLUE};
  }
`;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';

import AdminCard from './AdminCard';

export default function AdminList() {
  const [admins, setAdmins] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchAdmin() {
      try {
        setAdmins((await api.get('/admin')).data);
      } catch (err) {
        if (!err.response) {
          networkError();
        }
        DisplayError(
          'Não foi possível listar os administradores, por favor, tente novamente mais tarde'
        );
      }
    }
    fetchAdmin();
  }, []);

  return (
    <Container>
      <Card redirectTo="/admin/create">Adicionar administrador</Card>
      {admins &&
        admins.map((admin) => (
          <AdminCard
            key={admin.login + admin.id}
            name={admin.name}
            id={admin.id}
            login={admin.login}
            editOnPress={() =>
              history.push({
                pathname: `/admin/update/${admin.id}`,
                state: { admin },
              })
            }
            deleteOnPress={() =>
              history.push({
                pathname: `/admin/delete/${admin.id}`,
                state: { admin },
              })
            }
          />
        ))}
    </Container>
  );
}

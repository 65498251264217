import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};

  &:hover {
    background-color: ${(props) => props.theme.MAIN_THEME_COLOR_DARK};
  }

  & > a {
    padding: 0 15px;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
`;

import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api, { baseURL } from '~/services/api';

import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import Form from '~/components/Form';
import ImageShowcase from '~/components/ImageShowcase';

import networkError from '~/util/networkError';
import DisplayError from '~/util/DisplayError';

const MySwal = withReactContent(Swal);

export default function BannerDelete() {
  const history = useHistory();
  const { banner } = history.location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.delete(`/banner/delete/${banner.id}`);

      MySwal.fire({
        icon: 'success',
        title: 'Banner deletado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/banner');
    } catch (err) {
      if (err.response) {
        DisplayError(
          err?.response?.data?.error || 'Não foi possível deletar o banner'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <h1>Tem certeza que deseja deletar?</h1>
        <ImageShowcase src={`${baseURL}/banner/${banner.filename}`} alt="" />
        <Form onSubmit={handleSubmit}>
          <Button warning>Deletar</Button>
        </Form>
      </div>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 150px;
  height: 50px;
  display: grid;
  grid-template-row: 1fr;
  grid-template-columns: ${(props) =>
    props.options.update && props.options.destroy ? '1fr 1fr' : '1fr'};
`;

const Button = styled.button`
  width: 100%;
  font-weight: 600;
  color: white;
  border: none;
  height: 50px;

  &:hover {
    cursor: pointer;
  }
`;

function getRoundBorder() {
  return 'border-radius: 5px';
}

function getEditBorder({ options, noBottomRadius }) {
  if (
    (options.update && !options.destroy) ||
    (!options.update && options.destroy)
  )
    return getRoundBorder();
  return `border-radius: 5px 0px 0px ${noBottomRadius ? '0' : '5px'};`;
}

function getDeleteBorder({ options, noBottomRadius }) {
  if (
    (options.update && !options.destroy) ||
    (!options.update && options.destroy)
  )
    return getRoundBorder();
  return `border-radius: 0px 5px ${noBottomRadius ? '0px' : '5px'} 0px;`;
}

export const EditButton = styled(Button)`
  background-color: #f0a000;
  ${(props) => getEditBorder(props)}
`;

export const DeleteButton = styled(Button)`
  background-color: ${(props) => props.theme.WARNING};
  ${(props) => getDeleteBorder(props)}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Container, Title, Content } from './styles';

function NotificationItem({ title, content, queuedAt }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        {content.length > 25 ? `${content.slice(0, 25)}...` : content}
      </Content>
      <Content>{format(new Date(queuedAt), 'd/M/yyyy HH:mm:ss')}</Content>
    </Container>
  );
}

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  queuedAt: PropTypes.string.isRequired,
};

export default NotificationItem;

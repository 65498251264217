import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams, useHistory } from 'react-router-dom';

import { Title, Content } from './styles';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import Form from '~/components/Form';

const MySwal = withReactContent(Swal);

export default function AlertDelete() {
  const history = useHistory();
  const { id, alertId } = useParams();
  const { alert } = history.location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.delete(`/alert/${alertId}`);

      MySwal.fire({
        icon: 'success',
        title: 'Alerta deletado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push(`/alert/${id}`);
    } catch (err) {
      if (err.response) {
        DisplayError(
          'Não foi possível deletar o alert do tópico, por favor, tente novamente mais tarde'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <h1>Tem certeza que deseja deletar?</h1>
        <Title>{alert.fullTitle}</Title>
        <Content>{alert.fullContent}</Content>
        <Form onSubmit={handleSubmit}>
          <Button warning>Deletar</Button>
        </Form>
      </div>
    </Container>
  );
}

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { QueryClientProvider } from 'react-query';
import Global from './theme/global';

import Routes from './routes';
import history from '~/services/history';

import theme from '~/theme';

import { store } from './store';
import api from '~/services/api';
import apiInterceptor from '~/services/apiInterceptor';
import { queryClient } from './lib/queryClient';

apiInterceptor(api, store);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ToastContainer />
            <Global />
            <Routes />
          </Router>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;

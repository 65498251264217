import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Header, Logo, Options } from './styles';

import AnchorButton from '../AnchorButton';

export default function HeaderDesktop() {
  const signed = useSelector((state) => state.auth.signed);

  return (
    <Header>
      <Link to={signed ? '/dashboard' : '/login'}>
        <Logo />
      </Link>
      <Options>
        {signed ? (
          <>
            <AnchorButton href="/faq/topics">FAQ</AnchorButton>
            <AnchorButton href="/banner">Banners</AnchorButton>
            <AnchorButton href="/data">Datas de vencimento</AnchorButton>
            <AnchorButton href="/admin">Administradores</AnchorButton>
            <AnchorButton href="/account">Conta</AnchorButton>
            <AnchorButton href="/alert/type">Alertas</AnchorButton>
            <AnchorButton href="/notification">Notificações</AnchorButton>
            <AnchorButton href="/logoff">Sair</AnchorButton>
          </>
        ) : (
          <AnchorButton href="/login">Login</AnchorButton>
        )}
      </Options>
    </Header>
  );
}

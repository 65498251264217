/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import { isValidCEP } from '@brazilian-utils/brazilian-utils';
// import PhoneMask from 'react-native-masked-text/lib/masks/cel-phone.mask';

import isValidURL from '~/util/isValidURL';
import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';
import api, { baseURL } from '~/services/api';

import Container from '~/components/DefaultContainer';
import ImageShowcase from '~/components/ImageShowcase';
import ControlledInput from '~/components/ControlledInput';
import FileInput from '~/components/FileInput';
import Form from '~/components/Form';
import TextError from '~/components/TextError';
import Button from '~/components/Button';

const MySwal = withReactContent(Swal);

export default function StoreUpdate() {
  const [toUploadImage, setToUploadImage] = useState('');
  const history = useHistory();
  const { store } = history.location.state || {};

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    setValue('name', store.name);
    setValue('cep', store.cep);
    setValue('address', store.address);
    setValue('neighborhood', store.neighborhood);
    setValue('time', store.time);
    setValue('number', store.number);
    setValue('phone', store.phone);
    setValue('site', store.site);
    setValue('lat', store.lat);
    setValue('long', store.long);
  }, [store, setValue]);

  const onSubmit = async ({
    file,
    name,
    cep,
    address,
    neighborhood,
    time,
    number,
    phone,
    site,
    lat,
    long,
  }) => {
    try {
      const formDataToSend = new FormData();

      if (file) formDataToSend.append('file', file);
      formDataToSend.append('name', name);
      formDataToSend.append('cep', cep);
      formDataToSend.append('address', address);
      formDataToSend.append('neighborhood', neighborhood);
      formDataToSend.append('time', time);
      formDataToSend.append('number', number);
      formDataToSend.append('phone', phone);
      formDataToSend.append('site', site);
      formDataToSend.append('lat', lat);
      formDataToSend.append('long', long);

      await api.put(`/store/update/${store.id}`, formDataToSend, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });

      MySwal.fire({
        icon: 'success',
        title: 'Loja atualizada',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/stores');
    } catch (err) {
      if (err.response) {
        const { error } = err?.response?.data;
        DisplayError(
          error ||
            'Não foi possível cadastrar loja, tente novamente mais tarde e se o erro persistir contate o suporte'
        );
      } else {
        networkError();
      }
    }
  };

  async function findCEP(cep) {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json`);
      const { logradouro, bairro } = await response.json();
      setValue('address', logradouro);
      setValue('neighborhood', bairro);
    } catch (err) {
      setError('cep', { type: 'custom', message: 'Cep inválido' });
    }
  }

  function handleFileChoose(e) {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      setToUploadImage(event.target.result);
    };
    reader.readAsDataURL(file);
    clearErrors('file');

    setValue('file', file);
  }

  return (
    <Container>
      <div>
        <ImageShowcase
          src={toUploadImage || `${baseURL}/store/${store.filename}`}
          alt=""
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FileInput
            name="logo"
            label="Logo"
            accept="image/*"
            onChange={handleFileChoose}
          />
          <ControlledInput
            label="Nome"
            placeholder="Loja wavemax"
            name="name"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.name && <TextError>{errors.name.message}</TextError>}

          <ControlledInput
            label="Cep"
            placeholder="00000-000"
            name="cep"
            // onChange={(e) => console.log(e.target.value)}
            onChange={(e) => {
              if (e.target.value.length === 8) {
                if (isValidCEP(e.target.value)) {
                  findCEP(e.target.value);
                }
              }
            }}
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.cep && <TextError>{errors.cep.message}</TextError>}

          <ControlledInput
            label="Endereço"
            placeholder="Rua"
            name="address"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.address && <TextError>{errors.address.message}</TextError>}
          <ControlledInput
            label="numero"
            placeholder="123"
            name="number"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.number && <TextError>{errors.number.message}</TextError>}
          <ControlledInput
            label="Bairro"
            placeholder="Bairro"
            name="neighborhood"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.neighborhood && (
            <TextError>{errors.neighborhood.message}</TextError>
          )}

          <ControlledInput
            label="Telefone"
            placeholder="(00)00000-0000"
            name="phone"
            // onChange={(e) => {
            //   const formatted = phoneMask.getValue(e.target.value);
            //   setValue('phone', formatted);
            // }}
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.phone && <TextError>{errors.phone.message}</TextError>}

          <ControlledInput
            name="site"
            placeholder="https://wavemax.com.br/"
            label="Site da loja"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
              validate: (value) => isValidURL(value) || 'Link inválido',
            }}
          />
          {errors.site && <TextError>{errors.site.message}</TextError>}

          <ControlledInput
            label="Horario"
            placeholder="8:00 as 18:00"
            name="time"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.time && <TextError>{errors.time.message}</TextError>}

          <ControlledInput
            label="Latitude"
            placeholder="00.000000"
            name="lat"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.lat && <TextError>{errors.lat.message}</TextError>}

          <ControlledInput
            label="Longitude"
            placeholder="00.000000"
            name="long"
            register={register}
            registerOptions={{
              required: 'Campo obrigatório',
            }}
          />
          {errors.long && <TextError>{errors.long.message}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

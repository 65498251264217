import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Item from './Item';

export default function AlertList() {
  const [alerts, setAlerts] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    async function fetchItems() {
      try {
        setAlerts(
          (
            await api.get('/alert', {
              params: {
                type_id: id,
              },
            })
          ).data
            .filter((item) => item.type_id === Number(id))
            .map((item) => ({
              ...item,
              fullTitle: item.title,
              fullContent: item.content,
              title:
                item.title.length > 15
                  ? `${item.title.slice(0, 15)}...`
                  : item.title,
              content:
                item.content.length > 25
                  ? `${item.content.slice(0, 25)}...`
                  : item.content,
            }))
            .reverse()
        );
      } catch (err) {
        if (!err.response) {
          networkError();
          return;
        }
        DisplayError(
          err?.response?.data?.err ||
            'Não foi possível listar os alertas, por favor, tente novamente mais tarde'
        );
      }
    }
    fetchItems();
  }, []);

  return (
    <Container>
      <Card
        redirectTo="/notification/send"
        toState={{
          sendType: 'newsBoard',
          alertTypeId: id,
        }}
      >
        Adicionar alerta
      </Card>
      <Card
        redirectTo="/notification/send"
        toState={{
          sendType: 'all',
          alertTypeId: id,
        }}
      >
        Adicionar alerta junto com uma notificação
      </Card>
      {alerts &&
        alerts.map((alert) => (
          <Item
            key={alert.id}
            alert={alert}
            editOnPress={() =>
              history.push({
                pathname: `/alert/${id}/update/${alert.id}`,
                state: { alert },
              })
            }
            deleteOnPress={() =>
              history.push({
                pathname: `/alert/${id}/delete/${alert.id}`,
                state: { alert },
              })
            }
          />
        ))}
    </Container>
  );
}

import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Route from './Route';

import { signOut } from '~/store/modules/auth/actions';

import SignIn from '~/pages/SignIn';
import Dashboard from '~/pages/Dashboard';

import BannerCreate from '~/pages/BannerCreate';
import BannerUpdate from '~/pages/BannerUpdate';
import BannerDelete from '~/pages/BannerDelete';
import BannerList from '~/pages/BannerList';

import DatasVencimentoList from '~/pages/DatasVencimentoList';
import DatasVencimentoCreate from '~/pages/DatasVencimentoCreate';
import DatasVencimentoUpdate from '~/pages/DatasVencimentoUpdate';
import DatasVencimentoDelete from '~/pages/DatasVencimentoDelete';

import FAQTopicsCreate from '~/pages/FAQTopicsCreate';
import FAQTopicsUpdate from '~/pages/FAQTopicsUpdate';
import FAQTopicsDelete from '~/pages/FAQTopicsDelete';
import FAQTopicsList from '~/pages/FAQTopicsList';

import FAQItemsCreate from '~/pages/FAQItemsCreate';
import FAQItemsUpdate from '~/pages/FAQItemsUpdate';
import FAQItemsDelete from '~/pages/FAQItemsDelete';
import FAQItemsList from '~/pages/FAQItemsList';

import AdminList from '~/pages/AdminList';
import AdminCreate from '~/pages/AdminCreate';
import AdminUpdate from '~/pages/AdminUpdate';
import AdminDelete from '~/pages/AdminDelete';

import AlertRead from '~/pages/AlertRead';
import AlertList from '~/pages/AlertList';
import AlertDelete from '~/pages/AlertDelete';

import AlertTypeList from '~/pages/AlertTypeList';
import AlertTypeCreate from '~/pages/AlertTypeCreate';
import AlertTypeUpdate from '~/pages/AlertTypeUpdate';
import AlertTypeDelete from '~/pages/AlertTypeDelete';

import Account from '~/pages/Account';

import PushTemplate from '~/pages/PushTemplate';

import Notifications from '~/pages/Notification';
import SendNotification from '~/pages/Notification/SendNotification';

import Store from '~/pages/Store';
import StoreRegister from '~/pages/StoreRegister';
import StoreDelete from '~/pages/StoreDelete';
import StoreUpdate from '~/pages/StoreUpdate';

export default function Routes() {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route exact path="/" component={() => <Redirect to="/login" />} />
      <Route path="/login" component={SignIn} />
      <Route
        path="/logoff"
        component={() => {
          dispatch(signOut());
          return <Redirect to="/login" />;
        }}
      />
      <Route authorized exact path="/dashboard" component={Dashboard} />
      <Route authorized exact path="/alert/type" component={AlertTypeList} />
      <Route authorized path="/alert/type/create" component={AlertTypeCreate} />
      <Route
        authorized
        path="/alert/type/update/:id"
        component={AlertTypeUpdate}
      />
      <Route
        authorized
        path="/alert/type/delete/:id"
        component={AlertTypeDelete}
      />
      <Route authorized exact path="/alert/read" component={AlertRead} />
      <Route authorized exact path="/alert/:id" component={AlertList} />
      <Route
        authorized
        path="/alert/:id/delete/:alertId"
        component={AlertDelete}
      />
      <Route authorized exact path="/admin" component={AdminList} />
      <Route authorized path="/admin/create" component={AdminCreate} />
      <Route authorized path="/admin/update/:id" component={AdminUpdate} />
      <Route authorized path="/admin/delete/:id" component={AdminDelete} />
      <Route authorized exact path="/banner" component={BannerList} />
      <Route authorized path="/banner/create" component={BannerCreate} />
      <Route authorized path="/banner/update/:id" component={BannerUpdate} />
      <Route authorized path="/banner/delete/:id" component={BannerDelete} />
      <Route authorized exact path="/data" component={DatasVencimentoList} />
      <Route authorized path="/data/create" component={DatasVencimentoCreate} />
      <Route
        authorized
        path="/data/update/:id"
        component={DatasVencimentoUpdate}
      />
      <Route
        authorized
        path="/data/delete/:id"
        component={DatasVencimentoDelete}
      />
      <Route authorized exact path="/faq/topics" component={FAQTopicsList} />
      <Route authorized path="/faq/topics/create" component={FAQTopicsCreate} />
      <Route
        authorized
        path="/faq/topics/update/:id"
        component={FAQTopicsUpdate}
      />
      <Route
        authorized
        path="/faq/topics/delete/:id"
        component={FAQTopicsDelete}
      />
      <Route authorized exact path="/faq/items/:id" component={FAQItemsList} />
      <Route
        authorized
        path="/faq/items/:id/create"
        component={FAQItemsCreate}
      />
      <Route
        authorized
        path="/faq/items/:id/update/:itemId"
        component={FAQItemsUpdate}
      />
      <Route
        authorized
        path="/faq/items/:id/delete/:itemId"
        component={FAQItemsDelete}
      />
      <Route authorized path="/account" component={Account} />
      <Route
        authorized
        path="/notification/send"
        component={SendNotification}
      />
      <Route authorized path="/notification" component={Notifications} />

      <Route authorized exact path="/stores" component={Store} />
      <Route authorized path="/stores/register" component={StoreRegister} />
      <Route authorized path="/stores/delete/:id" component={StoreDelete} />
      <Route authorized path="/stores/update/:id" component={StoreUpdate} />
      <Route
        authorized
        exact
        path="/push-templates"
        component={PushTemplate.Index}
      />
      <Route
        authorized
        exact
        path="/push-templates/create"
        component={PushTemplate.Create}
      />
      <Route
        authorized
        exact
        path="/push-templates/:id/update"
        component={PushTemplate.Update}
      />
      <Route path="*" component={() => <h1>404</h1>} />
    </Switch>
  );
}

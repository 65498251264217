import React from 'react';
import { useHistory } from 'react-router-dom';

import { Title, Content } from './styles';

import Container from '~/components/DefaultContainer';

export default function AlertRead() {
  const history = useHistory();
  const { fullTitle, fullContent } = history.location.state || {};

  return (
    <Container>
      <div>
        <Title>{fullTitle}</Title>
        <Content>{fullContent}</Content>
      </div>
    </Container>
  );
}

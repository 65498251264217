import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import api from '~/services/api';

import StoreContainer from './StoreContainer';
import { Content, Container, Header, Button } from './styles';

export default function Store() {
  const history = useHistory();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    async function fetchStores() {
      try {
        setStores((await api.get('/store')).data);
      } catch (err) {
        if (!err.response) {
          networkError();
        }
        DisplayError(
          'Não foi possível listar as lojas, por favor, tente novamente mais tarde'
        );
      }
    }
    fetchStores();
  }, []);

  return (
    <Container>
      <Header>
        <h1>Lojas</h1>
        <Button onClick={() => history.push('/stores/register')}>Nova</Button>
      </Header>
      <Content>
        {stores &&
          stores.map((store) => (
            <StoreContainer
              key={store.id}
              file={store.filename}
              address={store.address}
              name={store.name}
              neighborhood={store.neighborhood}
              number={store.number}
              phone={store.phone}
              site={store.site}
              time={store.time}
              store={store}
            />
          ))}
      </Content>
    </Container>
  );
}

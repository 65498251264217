import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';
import networkError from '~/util/networkError';

import Input from '~/components/Input';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

const MySwal = withReactContent(Swal);

export default function AlertTypeCreate() {
  const history = useHistory();
  const [errors, setErrors] = useState({
    name: '',
  });
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleValidation = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'name') {
      if (!e.target.value) {
        errorsTemp.name = 'Campo obrigatório';
      } else {
        errorsTemp.name = '';
      }
    }
    return errorsTemp;
  };

  const handleChange = (e) => {
    setErrors(handleValidation(e));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const errorsTemp = handleValidation(e);
    setErrors(errorsTemp);
    e.preventDefault();

    if (errors.name) return;

    try {
      await api.post('/alert/type', {
        name: formData.name,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Tipo de alerta criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/alert/type');
    } catch (err) {
      if (err.response) {
        DisplayError(
          err?.response?.data?.error ||
            'Não foi possível criar o tipo de alerta, por favor, tente novamente mais tarde'
        );
      } else {
        networkError();
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input label="Nome" name="name" onChange={handleChange} />
          {errors && <TextError>{errors.name}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Container, Text } from './styles';

function Card({ children, onPress, redirectTo, toState }) {
  const history = useHistory();

  return (
    <Container
      onClick={() => {
        if (onPress) onPress();
        if (redirectTo) {
          history.push({
            pathname: redirectTo,
            state: toState,
          });
        }
      }}
    >
      <Text>{children}</Text>
    </Container>
  );
}

Card.propTypes = {
  children: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  redirectTo: PropTypes.string,
  toState: PropTypes.objectOf(PropTypes.any),
};

Card.defaultProps = {
  onPress: null,
  redirectTo: '',
  toState: {},
};

export default Card;
